import React, { useEffect, useRef, useState } from 'react';
import bgimg from '../Assets/Images/agriculture-pictures-ppsj59vfqlop02h9.jpg';
import leaf from '../Assets/Images/leaf.png';
import calltransfer from '../Assets/Images/call-transfer_11963290 (1).png';
import email from '../Assets/Images/email_17493161.png';
import flower from '../Assets/Images/flower-shop_12352975.png';
import axios from 'axios'; 

const ContactSection = () => {
  const [isInView, setIsInView] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // state for form submission status
  const [isSubmitting, setIsSubmitting] = useState(false); // state to track form submission progress
  const cardRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    message: '', 
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true when form submission starts
    try {
      const response = await axios.post('http://localhost:4000/api/v1/contact/send-mail', formData);
      setIsSubmitted(true); // Update state to show success message
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message');
    } finally {
      setIsSubmitting(false); // Reset submitting state after submission process ends
    }
  };

  return (
    <>
      <section
        className="flex flex-col md:flex-row h-auto md:h-screen bg-cover bg-center relative"
        style={{ backgroundImage: `url(${bgimg})` }}
        id="contact"
      >
        <div className="absolute inset-0 bg-black opacity-70"></div>

        <div className="w-full md:w-1/2 flex justify-center items-center p-6 relative z-10">
          <div
            ref={cardRef}
            className={`bg-white shadow-lg rounded-lg p-8 w-full max-w-md transform transition-all duration-1000 ease-in-out ${
              isInView ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
            } animate-breathe`}
          >
            {isSubmitted ? (
              <div className="text-center">
                <h3 className="text-3xl font-bold text-gray-800">Message Sent Successfully!</h3>
                <p className="text-green-600 text-lg mt-4">Thank you for getting in touch with us.
                  We'll get back to you soon.
                </p>
              </div>
            ) : (
              <>
                <div className="text-center mb-6">
                  <h6 className="text-green-600 text-lg font-semibold mb-2 flex items-center justify-center">
                    <img src={leaf} alt="Leaf" className="w-5 h-5 mr-2" />
                    CONTACT US
                    <img src={leaf} alt="Leaf" className="w-5 h-5 ml-2" />
                  </h6>
                  <h3 className="text-3xl font-bold text-gray-800">Get in Touch</h3>
                </div>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <textarea
                    name="message" // Ensure the name matches the state field
                    placeholder="Describe your request"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    value={formData.message} // Bind value to the state
                    onChange={handleChange} // Update the state on change
                  ></textarea>
                  <button
                    type="submit"
                    className="w-full bg-green-600 text-white py-3 rounded-md font-semibold hover:bg-green-700 transition duration-300"
                    disabled={isSubmitting} // Disable the button while submitting
                  >
                    {isSubmitting ? 'Submitting...' : 'SEND REQUEST'}
                  </button>
                </form>
              </>
            )}
          </div>
        </div>

        {/* Right side Contact Info */}
        <div className="w-full md:w-1/2 flex items-center justify-center relative z-10 h-96 md:h-auto">
          <div className="relative z-10 p-6">
            <ul className="space-y-8">
              <li className="flex items-center">
                <div className="w-16 h-16 rounded-full flex items-center justify-center mr-4">
                  <img src={calltransfer} alt="Call" className="w-12 h-12" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-white">Call This Now</h3>
                  <p className="text-white">
                    <a href="tel:+918618944125">+918618944125</a>
                  </p>
                </div>
              </li>
              <li className="flex items-center">
                <div className="w-16 h-16 rounded-full flex items-center justify-center mr-4">
                  <img src={email} alt="Email" className="w-12 h-12" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-white">Your Message</h3>
                  <p className="text-white">
                    <a href="mailto:connect@kiaanagrow.com">connect@kiaanagrow.com</a>
                  </p>
                </div>
              </li>
              <li className="flex items-center">
                <div className="w-16 h-16 rounded-full flex items-center justify-center mr-4">
                  <img src={flower} alt="Location" className="w-12 h-12" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-white">Our Location</h3>
                  <p className="text-white">Rajarajeshwari Nagar, Bengaluru – 560098.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Google Map */}
      <div className="google-map relative w-full h-96">
        <iframe
          title="Kiaan AgrowTech Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.923068481783!2d77.52400447484034!3d12.912665987397288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f02cb8e1313%3A0xf7f08010f477ecc4!2sKiaan%20AgrowTech!5e0!3m2!1sen!2sin!4v1724654386321!5m2!1sen!2sin"
          className="w-full h-full border-0"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default ContactSection;
