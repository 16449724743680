import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Irrigation from '../Assets/Images/smart-irri.png';
import Nutrition from '../Assets/Images/nutrition-mana.png';
import Tech from '../Assets/Images/tech-sol.png';
import Greenleaf from '../Assets/Images/greenl.png';
import Compost from '../Assets/Images/compost.png';
import Meristem from '../Assets/Images/meristeam biotech.jpeg'

const partners = [
  { name: 'Smart Irrigation', logo: Irrigation },
  { name: 'Nutrition Management', logo: Nutrition },
  { name: 'Tech Solutions', logo: Tech },
  { name: 'Plants Partner', logo: Greenleaf },
  { name: 'Compost Partner', logo: Compost },
  { name: 'Plants Partner', logo: Meristem },
];

// Updated animation variants for Framer Motion
const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
  hover: {
    scale: 1.05,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const PartnerCard = ({ name, logo, isVisible }) => (
  <motion.div
    className="relative group transition-all duration-300"
    variants={cardVariants}
    initial="hidden"
    animate={isVisible ? 'visible' : 'hidden'}
    whileHover="hover"
  >
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 transition-transform duration-300 ease-in-out">
      <motion.div
        className="w-[170px] h-[150px] bg-white rounded-full p-2 shadow-lg overflow-hidden"
        whileHover={{ scale: 1.1 }}
      >
        <img src={logo} alt={name} className="w-full h-full object-cover rounded-full" />
      </motion.div>
    </div>
    <motion.div
      className="bg-white rounded-lg pt-28 pb-8 px-6 w-full text-center shadow-md group-hover:bg-green-600 group-hover:text-white"
    >
      <h4 className="text-xl font-bold mb-3 transition-colors duration-300">{name}</h4>
    </motion.div>
  </motion.div>
);

const Partners = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(new Array(partners.length).fill(false));
  const partnerRefs = useRef([]);
  const [touchStartX, setTouchStartX] = useState(0);

  const visiblePartnerCount = 4;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % partners.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = partnerRefs.current.indexOf(entry.target);
          if (index !== -1) {
            setIsVisible((prev) => {
              const updatedVisibility = [...prev];
              updatedVisibility[index] = entry.isIntersecting;
              return updatedVisibility;
            });
          }
        });
      },
      { threshold: 0.1, rootMargin: '0px 0px -10% 0px' }
    );

    partnerRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      partnerRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  const getVisiblePartners = () => {
    const visiblePartners = [];
    for (let i = 0; i < visiblePartnerCount; i++) {
      const index = (currentIndex + i) % partners.length;
      visiblePartners.push(partners[index]);
    }
    return visiblePartners;
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX - touchEndX > 50) {
      setCurrentIndex((prev) => (prev + 1) % partners.length);
    } else if (touchStartX - touchEndX < -50) {
      setCurrentIndex((prev) => (prev - 1 + partners.length) % partners.length);
    }
  };

  return (
    <div className="relative py-12 md:py-20 bg-gradient-to-tr from-green-100 to-green-300 overflow-hidden">
      <h2 className="text-4xl mb-28 font-bold text-green-900 flex text-center justify-center mb-20">
        OUR PARTNERS
      </h2>
      
      {/* Desktop View with Auto-Slide */}
      <div className="hidden lg:grid lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
        {getVisiblePartners().map((partner, index) => (
          <div
            key={index}
            ref={(el) => (partnerRefs.current[index] = el)}
          >
            <PartnerCard {...partner} isVisible={isVisible[index]} />
          </div>
        ))}
      </div>
      
      {/* Mobile View */}
      <div
        className="lg:hidden relative max-w-[340px] mx-auto"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <PartnerCard {...partners[currentIndex]} isVisible={true} />
      </div>
    </div>
  );
};

export default Partners;