import Navbar from './Home/Navbar';
import Hero from './Home/Hero';
import Card from './Home/Solutions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Projects from './Home/Projects';
import Footer from './Home/Footer';
import FarmManagementSoftwarePromo from './Home/Ecom';
import ContactSection from './Home/Contact';
import ChangeMakersSection from './Home/ChangeMakers';
import Feature from './Home/Feature';
import Mission from './Home/Mission';
import Partners from './Home/Partners';
import Story from './Home/Story';
import ScrollToTopButton from './Home/ScrollToTop';
import Preloader from './Home/PreLoader';
import EcomSoftwarePromo from './Home/Farmsoft';


function App() {
  return (
    <div>
    <Preloader />
    <Navbar />
    <Hero />
    <Feature />
    <Story />
    <Card />
    <Mission />
    <EcomSoftwarePromo />
    <FarmManagementSoftwarePromo />
    <Projects />
    <Partners />
    <ChangeMakersSection />
    <ContactSection />
    <Footer />
    <ScrollToTopButton />
    </div>
  );
}

export default App;
