import React, { useState, useEffect } from 'react';
import logo from '../Assets/Logo/Kiaan-Logo-1-1.png';

const Preloader = ({ loadingTime = 1000 }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, loadingTime);

    return () => clearTimeout(timer);
  }, [loadingTime]);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-[#122f2a] z-[9991] transition-all duration-1000 ease-in-out
        ${isLoading ? 'opacity-100' : 'opacity-0 translate-y-[-100%]'}`}
    >
      <div 
        className="w-full h-full bg-no-repeat bg-center  animate-[flipInY_2s_infinite]"
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: 'clamp(80px, 15vw, 160px) auto'
        }}
      />
    </div>
  );
};

export default Preloader;