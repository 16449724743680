import React from 'react';
import { motion } from 'framer-motion';
import featurepic1 from '../Assets/Images/soil-secret-2-1.svg';
import featurepic2 from '../Assets/Images/soil-secret-2-2.svg';
import featurepic3 from '../Assets/Images/soil-secret-3-1.svg';

function Feature() {
  return (
    <section className="py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-tr from-green-100 to-green-300">
      <div className="container mx-auto h-full">
        <div className="h-full rounded-lg shadow-lg overflow-hidden bg-white sm:bg-teal-200 -mt-8 -mb-14">
          <ul className="flex flex-col sm:flex-row justify-between items-stretch h-full divide-y sm:divide-y-0 sm:divide-x divide-gray-300">
            {[
              { icon: featurepic1, title: "Access", description: "Access farm health insights through our farm management software's Phygital Network powered by Kiaan." },
              { icon: featurepic2, title: "Precision", description: "Grow your crops with precision using Kiaan's advanced farming solutions." },
              { icon: featurepic3, title: "Insights", description: "Leverage AI-powered recommendations and expert advice for informed decision-making." }
            ].map((item, index) => (
              <motion.li
                key={index}
                className="flex-1 p-4 sm:p-6 flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-4 transition-colors duration-300 hover:bg-white group"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <motion.div
                  className="flex-shrink-0"
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <img src={item.icon} alt={item.title} className="w-16 h-16 object-cover rounded-full bg-emerald-600 transition-transform duration-300 group-hover:scale-110" />
                </motion.div>
                <div className="text-center sm:text-left">
                  <h3 className="text-xl font-semibold text-emerald-800 mb-2 relative inline-block">
                    {item.title}
                    <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-emerald-800 transition-all duration-300 group-hover:w-full"></span>
                  </h3>
                  <p className="text-sm text-gray-600">{item.description}</p>
                </div>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Feature;