import React from "react";
import { FaFacebook, FaWhatsapp,  FaInstagram, FaPhone, FaMapMarkerAlt, FaClock, FaLinkedin } from "react-icons/fa";
import logo from '../Assets/Logo/Kiaan-Logo-1-1.png';

export default function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();
    return (
    <footer className="bg-customGreen text-white py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0">
          {/* Logo and Contact Information */}
          <div className="w-full md:w-1/3">
            <img src={logo} className="h-12 mb-6" alt="Kiaan Logo" />
            <div className="space-y-2">
              <p className="flex items-center">
                <FaClock className="mr-2" /> Open Hours: Mon - Fri: 9.00 am to 6.00 pm.
              </p>
              <p className="flex items-center">
                <FaMapMarkerAlt className="mr-2" /> 18, 3rd Cross, BEML Layout, 5th Stage,
              </p>
              <p>(Behind Swarga Rani School), Rajarajeshwari Nagar,</p>
              <p>Bengaluru - 560098.</p>
              <p className="flex items-center mt-4">
                <FaPhone className="mr-2" /> +918618944125
              </p>
            </div>
            <div className="flex space-x-4 mt-6">
              {/* <a href="#" aria-label="Facebook" className="text-white hover:text-green-500 transition duration-300">
                <FaFacebook size={24} />
              </a> */}
              <a href="https://wa.me/+918618944125?text=Hello%20there" aria-label="WhatsApp" className="text-white hover:text-green-500 transition duration-300">
                <FaWhatsapp size={24} />
              </a>
              {/* <a href="#" aria-label="X (formerly Twitter)" className="text-white hover:text-green-500 transition duration-300">
                <SiX size={24} />
              </a> */}
              <a href="https://www.instagram.com/kiaan.agrowtech?igsh=cDJsMmZqOThleWxx" aria-label="Instagram" className="text-white hover:text-green-500 transition duration-300">
                <FaInstagram size={24} />
              </a>
              <a href="https://www.linkedin.com/company/kiaanagrowtech/posts/?feedView=all" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-white hover:text-green-500 transition duration-300">
                <FaLinkedin size={24} />
              </a>
            </div>
          </div>

          {/* Service Links */}
          <div className="w-full md:w-1/3">
            <h4 className="text-xl font-semibold mb-4">Services</h4>
            <ul className="space-y-2">
              <li>
                <a className="hover:text-green-500 transition duration-300">Kiaaniot</a>
              </li>
              <li>
                <a className="hover:text-green-500 transition duration-300">KiaanKart-Launching Soon</a>
              </li>
              <li>
                <a className="hover:text-green-500 transition duration-300">Agri Consultancy</a>
              </li>
              <li>
                <a className="hover:text-green-500 transition duration-300">Irrigation Management</a>
              </li>
              <li>
                <a  className="hover:text-green-500 transition duration-300">Fertigation Management</a>
              </li>
              <li>
                <a className="hover:text-green-500 transition duration-300">Project Process Management</a>
              </li>
            </ul>
          </div>

          {/* Other Links */}
          <div className="w-full md:w-1/3">
            <h4 className="text-xl font-semibold mb-4">Links</h4>
            <ul className="space-y-2">
              <li>
                <a href="#solutions" className="hover:text-green-500 transition duration-300">Solutions</a>
              </li>
              <li>
                <a href="#about" className="hover:text-green-500 transition duration-300">About Us</a>
              </li>
              <li>
                <a href="#contact" className="hover:text-green-500 transition duration-300">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="text-center border-t border-gray-600 mt-8 pt-4">
          <p>© Copyright {currentYear} Kiaan AgrowTech Pvt Ltd. All rights reserved.</p>

        </div>
      </div>
    </footer>
  );
}