import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import solutions1 from '../Assets/Images/solution1.jpg';
import solutions2 from '../Assets/Images/solution2.jpg';
import solutions3 from '../Assets/Images/solution3.jpeg';
import solutions4 from '../Assets/Images/solution4.jpg';
import solutions5 from '../Assets/Images/solution5.jpg';

const FadeInText = ({ text }) => {
  return (
    <motion.span
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {text}
    </motion.span>
  );
};

export default function Solutions() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2, 
    };

    const handleIntersection = (entries) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="relative py-12 md:py-20 bg-gradient-to-tr from-green-100 to-green-300 overflow-hidden">
      <div id='solutions'>
        <div className="text-center mb-8 px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-green-900">
            Our Solutions
          </h2>
          {isVisible && (
            <p className="text-black text-xl md:text-2xl font-bold mt-8">
              <FadeInText text="We're empowering our customers and stakeholders by developing the product using comprehensive farm-level data and actionable insights, driving sustainable agricultural practices and informed decision-making." />
            </p>
          )}
        </div>

        <div className="px-2 md:px-4 lg:px-6">
          <Slider {...settings}>
            {/* First Card */}
            <div className="px-1 md:px-2">
              <div className="max-w-md mx-auto rounded overflow-hidden shadow-lg bg-white border-8 border-white hover:bg-gray-950 hover:text-white hover:border-gray-950 transition-colors duration-300">
                <img className="w-full h-56 md:h-64 lg:h-64 object-cover" src={solutions1} alt="Ai based disease management" />
                <div className="px-4 py-4">
                  <div className="font-bold text-xl md:text-2xl mb-2">
                    AI Based Disease Management
                  </div>
                  <p className="text-base  md:text-lg mb-2">
                    <span className='font-extrabold underline '>Launching Soon</span> - Our disease management detects and alerts crop diseases early, ensuring healthy plants and higher yields.
                  </p>
                </div>
              </div>
            </div>

            {/* Second Card */}
            <div className="px-1 md:px-2">
              <div className="max-w-md mx-auto rounded overflow-hidden shadow-lg bg-white border-8 border-white hover:bg-gray-950 hover:text-white hover:border-gray-950 transition-colors duration-300">
                <img className="w-full h-56 md:h-64 lg:h-64 object-cover" src={solutions2} alt="IoT sensors" />
                <div className="px-4 py-4">
                  <div className="font-bold text-xl md:text-2xl mb-2">
                    IoT Sensors
                  </div>
                  <p className="text-base md:text-lg mb-2">
                    Kiaan's IoT sensors monitor crops and environmental conditions, providing real-time data for smart farming decisions.
                  </p>
                </div>
              </div>
            </div>

            {/* Third Card */}
            <div className="px-1 md:px-2">
              <div className="max-w-md mx-auto rounded overflow-hidden shadow-lg bg-white border-8 border-white hover:bg-gray-950 hover:text-white hover:border-gray-950 transition-colors duration-300">
                <img className="w-full h-56 md:h-64 lg:h-64 object-cover" src={solutions3} alt="Smart irrigation" />
                <div className="px-4 py-4">
                  <div className="font-bold text-xl md:text-2xl mb-2">
                    Smart Irrigation
                  </div>
                  <p className="text-base md:text-lg mb-2">
                    Kiaan's smart irrigation optimizes water usage using AI and IoT for efficient and sustainable farming.
                  </p>
                </div>
              </div>
            </div>

            {/* Fourth Card */}
            <div className="px-1 md:px-2">
              <div className="max-w-md mx-auto rounded overflow-hidden shadow-lg bg-white border-8 border-white hover:bg-gray-950 hover:text-white hover:border-gray-950 transition-colors duration-300">
                <img className="w-full h-56 md:h-64 lg:h-64 object-cover" src={solutions4} alt="Precision farming" />
                <div className="px-4 py-4">
                  <div className="font-bold text-xl md:text-2xl mb-2">
                  Security & Surveillance
                  </div>
                  <p className="text-base md:text-lg mb-2">
                  Safeguard farms against intruders and threats with instant alerts using advanced surveillance solutions.
                  </p>
                </div>
              </div>
            </div>
            {/* Fifth Card */}
            <div className="px-1 md:px-2">
              <div className="max-w-md mx-auto rounded overflow-hidden shadow-lg bg-white border-8 border-white hover:bg-gray-950 hover:text-white hover:border-gray-950 transition-colors duration-300">
                <img className="w-full h-56 md:h-64 lg:h-64 object-cover" src={solutions5} alt="Precision farming" />
                <div className="px-4 py-4">
                  <div className="font-bold text-xl md:text-2xl mb-2">
                  Agri Consultancy and Guidance 
                  </div>
                  <p className="text-base md:text-lg mb-2">
                 Our Experienced agronomists guide farmers to increase yields, reduce costs, and promote sustainable farming practices.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}