import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import KiaanButton from './KiaanButton';
import smartagripic from '../Assets/Images/smart-agriculture-2.jpg';
import agripic from '../Assets/Images/new2.jpeg';
import bananapic from '../Assets/Images/banana-2828905.jpg';
import sliderpic from '../Assets/Images/slider-2-overlay.png';
import playbuttonpic from '../Assets/Images/play_12052806.png';

export default function MainSlider() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [typedTitle, setTypedTitle] = useState('');
    const [charIndex, setCharIndex] = useState(0);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const titleRef = useRef(null);

    const images = [smartagripic, agripic, bananapic];
    const fullTitle = "Imagine a new age of agriculture where data transforms everything";
    const videoUrl = "https://www.youtube.com/embed/wC-hg3ZuTWE?autoplay=1&mute=1";

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        let typingInterval;
        if (charIndex < fullTitle.length) {
            typingInterval = setTimeout(() => {
                setTypedTitle((prev) => prev + fullTitle[charIndex]);
                setCharIndex((prev) => prev + 1);
            }, 40);
        }
        return () => clearTimeout(typingInterval);
    }, [charIndex, fullTitle]);

    const openVideoModal = () => {
        setIsVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setIsVideoModalOpen(false);
    };

    return (
        <section className="relative h-screen overflow-hidden" id="home">
            {/* Image slider */}
            <div className="carousel w-full h-full relative">
                {images.map((img, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, scale: 1.1 }}
                        animate={{ opacity: index === currentSlide ? 1 : 0, scale: 1 }}
                        transition={{ duration: 1.5, ease: 'easeInOut' }}
                        className="absolute inset-0 w-full h-full"
                    >
                        <img
                            src={img}
                            alt={`Kiaan Agriculture ${index + 1}`}
                            className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                    </motion.div>
                ))}

                {/* Overlay Animation */}
                {/* Overlay Animation */}
<motion.div
  initial={{ x: '-100%' }}
  animate={{ x: '0%' }}
  transition={{ duration: 2, ease: 'easeInOut' }}
  className="absolute inset-0 bg-left-top bg-no-repeat z-10"
  style={{
    backgroundImage: `url(${sliderpic})`,
    backgroundSize: 'cover', // Make sure the image scales correctly
    backgroundPosition: 'center', // Ensure the image is centered
  }}
></motion.div>


                {/* Hero text and buttons */}
                <motion.div
                    className="absolute bottom-20 left-0 flex items-end sm:ml-36 justify-items-end sm:mb-24 z-20"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 1.5, ease: 'easeInOut' }}
                >
                    <div className="max-w-4xl mx-auto text-white px-4 sm:px-0 md:mb-30">
                        <motion.h5
                            className="text-lg sm:text-xl lg:text-2xl font-semibold bg-customFadedGreen text-white rounded-full px-4 py-2 inline-flex items-center  sm:-ml-32 lg:-ml-24"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 1, duration: 0.8 }}
                        >
                            <span className="w-3 h-3  rounded-full outline-dotted mr-2 "></span>
                            Kiaan Agrow
                        </motion.h5>

                        <motion.h2
                            ref={titleRef}
                            className="text-3xl sm:text-4xl lg:text-6xl font-bold mt-2 mb-4 sm:-ml-32 lg:-ml-24 min-h-[2em]"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.5, duration: 1 }}
                        >
                            {typedTitle}
                        </motion.h2>
                        <p className="mb-6 text-base sm:text-lg lg:text-lg sm:-ml-32 lg:-ml-24 font-serif">
                            We empower everyone with seamless access to vital services and information.
                            Join us as we revolutionize farming with the power of data.
                        </p>
                        <div className="flex flex-col sm:-ml-32 mr-4 sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 lg:-ml-24">
                            <KiaanButton href="#farmsoftware">Explore More</KiaanButton>
                            {/* Play Button with Enhanced Effects */}
                            <motion.button
                                onClick={openVideoModal}
                                className="relative block w-16 h-16 overflow-hidden rounded-full bg-gradient-to-r from-green-400 to-blue-500 group"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ delay: 2, duration: 1 }}
                                whileHover={{ scale: 1.2 }}
                            >
                                <img
                                    src={playbuttonpic}
                                    alt="Play Button"
                                    className="w-full h-full transition-all duration-300 ease-out group-hover:rotate-90"
                                />
                                {/* Glow and Ripple Effects */}
                                <span className="absolute inset-0 rounded-full border-2 border-transparent animate-pulse group-hover:border-green-500"></span>
                                <span className="absolute inset-0 w-full h-full rounded-full bg-white opacity-10 blur-md group-hover:opacity-30 transition-opacity duration-500"></span>
                            </motion.button>
                        </div>
                    </div>
                </motion.div>
            </div>

            {/* Responsive Video Modal */}
            <AnimatePresence>
                {isVideoModalOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
                        onClick={closeVideoModal}
                    >
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.5, opacity: 0 }}
                            className="bg-black rounded-lg w-full max-w-4xl"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="relative pt-[56.25%]">
                                <iframe
                                    className="absolute top-0 left-0 w-full h-full rounded-t-lg"
                                    src={videoUrl}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className="p-4 flex justify-center">
                                <button
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    onClick={closeVideoModal}
                                >
                                    Close
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </section>
    );
}