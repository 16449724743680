import React, { useState, useEffect, useRef } from 'react';
import { FaLinkedin } from 'react-icons/fa';
import Founder1 from '../Assets/Images/kaviraj.png';
import Founder2 from '../Assets/Images/nagendra.png';
import Founder3 from '../Assets/Images/pradeep.jpeg';

// TypeWriter component with framer-motion

const TeamMember = ({ name, role, image, linkedInUrl }) => (
  <div className="flex flex-col items-center text-center">
    <img src={image} alt={name} className="w-40 h-40 rounded-full mb-4 object-cover shadow-lg" />
    <h3 className="font-bold text-xl text-gray-800 mb-2">{name}</h3>
    <p className="text-sm text-gray-600 mb-4">{role}</p>
    <a
      href={linkedInUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center justify-center text-white bg-blue-600 rounded-full px-4 py-2 text-sm font-medium hover:bg-blue-700 transition-colors duration-300"
    >
      <FaLinkedin className="mr-2" /> Connect on LinkedIn
    </a>
  </div>
);

const TeamShowcase = () => {
  const [isTyping, setIsTyping] = useState(false);
  const pRef = useRef(null);

  // Intersection Observer to detect when the <p> tag is fully visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsTyping(true); // Start typing when fully visible
          } else {
            setIsTyping(false); // Stop typing and reset when partially visible
          }
        });
      },
      { threshold: 1 } // Trigger only when fully visible
    );

    if (pRef.current) {
      observer.observe(pRef.current);
    }

    return () => {
      if (pRef.current) {
        observer.unobserve(pRef.current);
      }
    };
  }, []);

  const founders = [
    {
      name: "KAVIRAJ H K",
      role: "Co-Founder",
      image: Founder1,
      linkedInUrl: "https://www.linkedin.com/in/kaviraj-hornebyle-aa389211/",
    },
    {
      name: "NAGENDRA KALKULI",
      role: "Co-Founder",
      image: Founder2,
      linkedInUrl: "https://www.linkedin.com/in/nagendrakalkuli/",
    },
    {
      name: "PRADEEP KUMAR ML",
      role: "Co-Founder",
      image: Founder3,
      linkedInUrl: "https://www.linkedin.com/in/pradeep-m-l-54928321/",
    },
  ];

  return (
    <div className="bg-gradient-to-br from-green-100 to-green-300 py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-8">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-950 mb-6 text-center px-4">
  MEET OUR CHANGE MAKERS
</h2>
            <div className="text-center text-lg mb-12 px-6 font-bold " ref={pRef}>
              
                 "Our vision is to lead the global AgroTech industry by championing smart and sustainable technologies that drive positive change in agriculture."
                
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
              {founders.map((founder, index) => (
                <TeamMember key={index} {...founder} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamShowcase;
