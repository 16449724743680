import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import projectpic1 from '../Assets/Images/banana-2828905.jpg';
import projectpic2 from '../Assets/Images/project2.jpeg';
import projectpic3 from '../Assets/Images/project3.jpeg';
import projectpic4 from '../Assets/Images/project4.jpg';
import projectpic5 from '../Assets/Images/project5.jpg';

const images = [
  {
    src: projectpic1,
    alt: "FarmLand",
    title: "AGARA",
    description: "3 Acres Of Yellaki Banana, 1 Acre Of G9 Banana, 1 Acre Of Tomato, 1 Acre Of Chilli."
  },
  {
    src: projectpic2,
    alt: "FarmLand",
    title: "AVAREGERE",
    description: "10 Acres Of Yellaki Banana, 10 Acres Of Chilli."
  },
  {
    src: projectpic3,
    alt: "FarmLand",
    title: "CHAMALAPURA",
    description: "10 Acres Of Yellaki Banana, 5 Acres Of G9 Banana, 10 Acres Of Watermelon."
  },
  {
    src: projectpic4,
    alt: "FarmLand",
    title: "MYSURU RURAL",
    description: "40 Acres Of Yellaki Banana."
  },
  {
    src: projectpic5,
    alt: "FarmLand",
    title: "MYSURU OUTSKIRTS",
    description: "60 Acres Of Yellaki Banana."
  }
];

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  

  useEffect(() => {
    // Check if the screen width is mobile/tablet
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }

    if (touchStart - touchEnd < -50) {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="bg-gradient-to-br from-green-100 to-green-300 flex items-center justify-center min-h-screen p-4 md:p-6" id="projects">
      <div className="max-w-7xl w-full">
      <h2 className="text-4xl mb-10 -mt-8 font-bold text-green-900 flex text-center justify-center mb-20">
        
        OUR PROJECTS
      </h2>

        <div className="relative overflow-hidden rounded-2xl shadow-2xl">
          {/* Main image display */}
          <div
            className="h-[400px] md:h-[600px]"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {images.map((image, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
                  index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'
                }`}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="h-full w-full object-cover object-center"
                />
               <div className="absolute bottom-0 left-0 right-0 p-4 md:p-6 bg-black bg-opacity-50 text-white">
  <h2 className="text-2xl md:text-3xl font-bold">{image.title}</h2>
  <p className="text-sm md:text-lg mt-2">{image.description}</p>
</div>

              </div>
            ))}
          </div>

          {/* Scroll buttons (Hide on mobile/tablet) */}
          {!isMobile && (
            <>
              <button
                className="absolute top-1/2 left-2 md:left-4 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity duration-300 rounded-full p-1 md:p-2 focus:outline-none z-20"
                onClick={handlePrev}
              >
                <ChevronLeft className="w-4 h-4 md:w-6 md:h-6 text-white" />
              </button>
              <button
                className="absolute top-1/2 right-2 md:right-4 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity duration-300 rounded-full p-1 md:p-2 focus:outline-none z-20"
                onClick={handleNext}
              >
                <ChevronRight className="w-4 h-4 md:w-6 md:h-6 text-white" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;