import React, { useState, useEffect, useRef } from 'react';
import Smallleafimage from '../Assets/Images/smallleaf_pic.jpg';
import KiaanButton from './KiaanButton';
import videoSrc from '../Assets/Videos/Drone_Video.mp4';
const Story = () => {
    const [typedStory, setTypedStory] = useState(''); // For typing effect
    const [isTyping, setIsTyping] = useState(true);   // Track if text is typing
    const fullStory = "For centuries, agriculture has operated on traditional practices, lacking data and scientific efficiency.";
    const sectionRef = useRef(null); 
    const videoRef = useRef(null);                 // Ref to the section for intersection observer

    useEffect(() => {
        let storyIndex = 0;
        let interval;

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,  // Trigger when 20% of the section is in view
        };

        const handleIntersection = (entries) => {
            const [entry] = entries;
            setIsTyping(entry.isIntersecting);
            if (entry.isIntersecting && videoRef.current) {
                videoRef.current.play();
            } else if (videoRef.current) {
                videoRef.current.pause();
            }  // Start typing when section is visible
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        if (sectionRef.current) {
            observer.observe(sectionRef.current);  // Observe the sectionRef
        }

        const animateText = () => {
            interval = setInterval(() => {
                if (isTyping) {
                    // Typing forward
                    if (storyIndex <= fullStory.length) {
                        setTypedStory(fullStory.slice(0, storyIndex));
                        storyIndex++;
                    } else {
                        clearInterval(interval);
                    }
                } else {
                    // Typing backward (deleting)
                    if (storyIndex > 0) {
                        setTypedStory(fullStory.slice(0, storyIndex));
                        storyIndex--;
                    } else {
                        clearInterval(interval);
                    }
                }
            }, 20); // Adjust speed as needed
        };

        animateText();

        return () => {
            clearInterval(interval);
            observer.disconnect();  // Disconnect observer on cleanup
        };
    }, [isTyping]);  // Re-run effect when isTyping changes

    return (
        <section
            ref={sectionRef}
            className="relative py-12 md:py-20 bg-gradient-to-br from-green-100 to-green-300 overflow-hidden"
        >
            <div className="container mx-auto px-4" id='about'>
                <div className="flex flex-col lg:flex-row items-center">
                    {/* Left side image */}
                    <div className={`lg:w-1/2 mb-8 lg:mb-0 animateFadeInLeft`}>
                        <div className="relative">
                        <video
        ref={videoRef}
        className="w-full rounded-lg shadow-lg transition-transform duration-700 ease-in-out transform 
                   hover:scale-105 hover:shadow-2xl"
        loop
        muted
        playsInline
        src={videoSrc}
        onMouseEnter={() => videoRef.current.play()}
        onMouseLeave={() => videoRef.current.pause()}
    >
        Your browser does not support the video tag.
    </video>
    <div className="absolute inset-0 bg-black bg-opacity-30 rounded-lg 
                   transition-opacity duration-500 ease-in-out group-hover:bg-opacity-20">
    </div>
                            <div className="absolute right-2 top-1 max-w-[100px] lg:max-w-[150px] md:max-w-[130px] border-white rounded shadow-lg animate-movebounce3 md:right-3">
                                <img src={Smallleafimage} alt="Small leaf" className="w-full border-4 rounded-lg" />
                            </div>
                        </div>
                    </div>

                    {/* Right side story text */}
                    <div className={`lg:w-1/2 pl-8 animateFadeInRight`}>
                        <div className="text-left">
                            <h2 className="text-5xl md:text-4xl text-[rgb(2,58,16)] mb-4 md:mb-2 font-bold">Our Story</h2>
                            <h3 className="text-2xl mb-4 text-green-700 font-serif">{typedStory}</h3>
                        </div>
                        <p className="font-bold mb-6 text-black ">
                            Kiaan Agrowtech uses data and analytics to revolutionize farming at every stage,
                            integrating advanced technologies to enhance crop monitoring, automate farm management,
                            and optimize resource usage.
                        </p>
                        <div>
                            <KiaanButton href="#whykiaan">
                                <span className="relative z-10 font-semibold text-green-950">About More</span>
                            </KiaanButton>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Story;
